import { useRef, useEffect } from "react";

const Video = ({ selectedVideoIndex, index, videoUrl }) => {
  const videoRef = useRef(null);
  videoRef.current && (videoRef.current.currentTime = 0);

  useEffect(() => {
    const checkVideo = async () => {
      try {
        if (videoRef.current && selectedVideoIndex === index) {
          await videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      } catch (error) {
        console.error("Error playing video: ", error);
      }
    };
    checkVideo();
  }, [selectedVideoIndex, index]);

  return (
    <video
      ref={videoRef}
      playsInline
      autoPlay
      muted
      loop
      style={{
        width: "100%",
        height: selectedVideoIndex === index ? "450px" : "350px",
        transition: "height 0.5s ease",
      }}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
